<script>
import store from '@/infrastructure/store'
import DependentCard from '@/features/userDependents/components/DependentCard'

export default {
  components: { DependentCard },

  props: {
    dependents: { type: Array, required: true },
    itemCssClass: { type: String },
  },

  methods: {
    setDependentToImpersonate(dependent) {
      store.commit('setUserToImpersonate', { userId: dependent.userId, fullName: dependent.fullName })
      this.$router.push({ name: 'home' })
    },

    async removeDependent(dependent) {
      await store.dispatch('userDependents/removeUserDependent', { dependentUserId: dependent.userId })
    },
  },
}
</script>

<template>
  <div>
    <DependentCard
      v-for="dependent in dependents"
      :key="dependent.userId"
      :full-name="dependent.fullName"
      :dependent-type="dependent.type"
      :class="itemCssClass"
      :remove-dependent="() => removeDependent(dependent)"
      @impersonate="setDependentToImpersonate(dependent)"
    />
  </div>
</template>
