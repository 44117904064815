<script>
export default {
  props: {
    cardMenuActions: { type: Array, required: true },
  },

  data() {
    return {
      isCardMenuOpen: false,

      busyActions: [],
    }
  },

  computed: {
    hasAnyActions() {
      return this.cardMenuActions.length > 0
    },
  },

  methods: {
    addBusyAction(action) {
      if (!this.busyActions.includes(action)) {
        this.busyActions.push(action)
      }
    },

    removeBusyAction(action) {
      const busyIdx = this.busyActions.indexOf(action)
      if (busyIdx >= 0) {
        this.busyActions.splice(busyIdx, 1)
      }
    },

    async onCardMenuActionClicked(action) {
      this.addBusyAction(action)

      try {
        await action.actionFunc()
      } finally {
        this.isCardMenuOpen = false
        this.removeBusyAction(action)
      }
    },
  },
}
</script>

<template>
  <v-menu v-model="isCardMenuOpen" bottom left :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn v-show="hasAnyActions" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </slot>
    </template>

    <v-list>
      <template v-for="(action, i) in cardMenuActions">
        <v-list-item v-if="action.actionFunc" :key="action.key" icon @click="onCardMenuActionClicked(action)">
          <v-list-item-action class="mr-1">
            <v-icon left>{{ action.icon }} {{ action.isActionBusy || busyActions.includes(action) ? 'mdi-spin' : '' }} </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> {{ action.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-else :key="`${i}-divider`" />
      </template>
    </v-list>
  </v-menu>
</template>
