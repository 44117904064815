<script>
import store from '@/infrastructure/store'

export default {
  data() {
    const minChildNameLength = 2
    const maxChildNameLength = 50
    return {
      minChildNameLength,
      maxChildNameLength,

      rules: {
        required: (value) => !!value || 'Dependent name is required',
        minChildNameLength: (value) => (value || '').length >= minChildNameLength || `Min ${minChildNameLength} characters`,
        maxChildNameLength: (value) => (value || '').length <= maxChildNameLength || `Max ${maxChildNameLength} characters`,
      },

      formValid: false,
      dependentName: '',
      busySaving: false,
    }
  },
  methods: {
    async addUserDependent() {
      this.busySaving = true
      try {
        await store.dispatch('userDependents/addUserDependent', { fullName: this.dependentName, dependentType: 'child' })
        this.clearForm()
      } finally {
        this.busySaving = false
      }
    },

    clearForm() {
      this.dependentName = ''

      this.$refs.form.reset()
    },

    onDependentNameFieldBlur() {
      if (!this.dependentName) {
        this.$nextTick(() => this.clearForm())
      }
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title>
      <div class="mx-auto">Add your child</div>
    </v-card-title>

    <v-card-text class="text-center">
      <v-icon x-large>mdi-human-male-child</v-icon>
    </v-card-text>

    <v-card-text>
      <v-form ref="form" v-model="formValid" autocomplete="off">
        <v-text-field
          label="Your child's full name"
          v-model="dependentName"
          filled
          :rules="[rules.required, rules.minChildNameLength, rules.maxChildNameLength]"
          counter
          :minlength="minChildNameLength"
          :maxlength="maxChildNameLength"
          name="child-name"
          autocomplete="child-name"
          clearable
          @click:clear="clearForm"
          @blur="onDependentNameFieldBlur"
        />
      </v-form>

      <div class="pt-3"></div>

      <div class="d-flex align-center">
        <v-spacer />
        <v-btn color="primary" :disabled="!formValid || busySaving" :loading="busySaving" @click="addUserDependent"> Add dependent </v-btn>
      </div>

      <div class="pt-1"></div>
    </v-card-text>
  </v-card>
</template>
