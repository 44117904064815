<script>
import DependentsList from '@/features/userDependents/components/DependentsList'
import AddDependent from '@/features/userDependents/components/AddDependent'
import store from '@/infrastructure/store'

export default {
  components: {
    DependentsList,
    AddDependent,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    dependents: () => store.state.userDependents.dependents,
  },

  async created() {
    this.isLoading = true
    try {
      await store.dispatch('userDependents/fetchUserDependents')
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>
        <div class="mx-auto text-center">Your dependents</div>
      </v-card-title>
    </v-card>

    <DependentsList :dependents="dependents" item-css-class="mt-3" />

    <div class="pt-3"></div>

    <AddDependent />
  </div>
</template>
