<script>
import MenuWithActions from '@/infrastructure/components/MenuWithActions'

export default {
  components: { MenuWithActions },

  props: {
    fullName: { type: String, required: true },
    dependentType: { type: String, required: true },
    removeDependent: { type: Function, required: true },
  },

  computed: {
    menuActions() {
      const actions = []

      actions.push({
        key: 'delete',
        actionFunc: async () => {
          if (!confirm(`Are you sure you want to remove ${this.fullName} as your dependent?`)) {
            return
          }

          await this.removeDependent()
        },
        icon: 'mdi-trash-can-outline',
        title: 'Delete',
      })

      return actions
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title class="d-flex align-center">
      <v-icon v-if="dependentType === 'Child'">mdi-human-male-child</v-icon>
      <v-icon v-else>mdi-question</v-icon>

      <div class="ml-1">{{ fullName }}</div>
    </v-card-title>

    <v-card-text class="d-flex">
      <v-btn color="success" outlined @click="$emit('impersonate')">
        <v-icon left>mdi-account-convert</v-icon>
        Impersonate
      </v-btn>

      <v-spacer />

      <MenuWithActions :card-menu-actions="menuActions" />
    </v-card-text>
  </v-card>
</template>
